function decodeGorillaCompression(data, nb_values) {

	// const fs = require('fs');

	// let rawdata = fs.readFileSync('gorillaTestData.json');
	// let student = JSON.parse(rawdata);
	// data = student.test
	if (data == undefined || (data.constructor === Array && data.length == 0)) {
		return []
	}

	function getBits(number, length) {
		var text = '';
		  for (var i = length - 1; i > -1; --i) {
			text += 0x01 & (number >> i);
		}
		  return text;
	}
	
	function BitArray(byteArray) {
		  this.getSize = function() {
			return 8 * byteArray.length;
		};
		this.getBit = function(index) {
			var byteIndex = Math.floor(index / 8);
			  var bitIndex = index - 8 * byteIndex;
			  var byteValue = byteArray[byteIndex];
			  if (byteValue < 0 || byteValue > 255) {
				throw new Error('Array item must be byte (in range: 0-255).');
			}
			  var bitValue = (byteValue >>> bitIndex) & 0x01;
			  return bitValue;
		};
		  this.setBit = function(index, value) {
			var byteIndex = Math.floor(index / 8);
			  var bitIndex = index - 8 * byteIndex;
			  var byteValue = byteArray[byteIndex];
			  if (byteValue < 0 || byteValue > 255) {
				throw new Error('Array item must be byte (in range: 0-255).');
			}
			  var maskValue = 0x01 << bitIndex;
			  byteArray[byteIndex] = value ? (byteValue | maskValue) : (byteValue & ~maskValue);
		};
		  this.toString = function(delimiter) {
			  if (delimiter == null) {
				delimiter = ' ';
			}
			var result = '';
			  for (var i = 0; i < byteArray.length; ++i) {
				  if (i > 0) {
					result += delimiter;
				}
				  var byte = byteArray[i];
				  if (byte < 0 || byte > 255) {
					throw new Error('Array item must be byte (in range: 0-255).');
				}
				  result += getBits(byte, 8);
			}
			  return result;
		};
	}
	
	
	
	
	var INIT_CONSTS = {
		'f64': {
			'n_bits_value': 64,
			'n_bits_number_of_leading_zeros': 5,
			'n_bits_length_of_the_meaningful_xored_value': 6,
			'pack_format': '>d',
		},
		'f32': {
			'n_bits_value': 32,
			'n_bits_number_of_leading_zeros': 4,
			'n_bits_length_of_the_meaningful_xored_value': 5,
			'pack_format': '>f',
		},
		'f16': {
			'n_bits_value': 16,
			'n_bits_number_of_leading_zeros': 3,
			'n_bits_length_of_the_meaningful_xored_value': 4,
			'pack_format': '>e',
		},
	}
	
	var BLOCK_SIZE_ADJUSTMENT = 1
	
	
	
	var b = Buffer.from(data, 'base64')
	
	var bit_array = new BitArray(b);

	
	
	var n_bits_value = INIT_CONSTS['f64']['n_bits_value']
	var n_bits_number_of_leading_zeros = INIT_CONSTS['f64'][
		'n_bits_number_of_leading_zeros']
	var n_bits_length_of_the_meaningful_xored_value = INIT_CONSTS[
		'f64']['n_bits_length_of_the_meaningful_xored_value']
	var pack_format = INIT_CONSTS['f64']['pack_format']
	
	var is_first_value_read = false
	var n_leading_zeros = null
	var n_trailing_zeros = null
	var length_of_the_meaningful_xored_value = null
	var previous_value_bits = null
	var previous_value = null
	
	
	bit_array = bit_array.toString()
	
	// May want to uncomment below
	bit_array = bit_array.replace(/\s/g, '');


	
	
	// const jspack = require('jspack');
	// import Unpack from jspack
	
	
	function Unpack(fmt, a, p)
		{
		// Module-level (private) variables
		var el,  bBE = false;
	
		// Raw byte arrays
		var _DeArray = function (a, p, l)
		{
			return [a.slice(p,p+l)];
		};
		var _EnArray = function (a, p, l, v)
		{
			for (var i = 0; i < l; a[p+i] = v[i]?v[i]:0, i++);
		};
	
		// ASCII characters
		var _DeChar = function (a, p)
		{
			return String.fromCharCode(a[p]);
		};
		var _EnChar = function (a, p, v)
		{
			a[p] = v.charCodeAt(0);
		};
	
		// Little-endian (un)signed N-byte integers
		var _DeInt = function (a, p)
		{
			var lsb = bBE?(el.len-1):0, nsb = bBE?-1:1, stop = lsb+nsb*el.len, rv, i, f;
			for (rv = 0, i = lsb, f = 1; i != stop; rv+=(a[p+i]*f), i+=nsb, f*=256);
			if (el.bSigned && (rv & Math.pow(2, el.len*8-1))) { rv -= Math.pow(2, el.len*8); }
			return rv;
		};
		var _EnInt = function (a, p, v)
		{
			var lsb = bBE?(el.len-1):0, nsb = bBE?-1:1, stop = lsb+nsb*el.len, i;
			v = (v<el.min)?el.min:(v>el.max)?el.max:v;
			for (i = lsb; i != stop; a[p+i]=v&0xff, i+=nsb, v>>=8);
		};
	
		// ASCII character strings
		var _DeString = function (a, p, l)
		{
			for (var rv = new Array(l), i = 0; i < l; rv[i] = String.fromCharCode(a[p+i]), i++);
			return rv.join('');
		};
		var _EnString = function (a, p, l, v)
		{
			for (var t, i = 0; i < l; a[p+i] = (t=v.charCodeAt(i))?t:0, i++);
		};
	
		// Little-endian N-bit IEEE 754 floating point
		var _De754 = function (a, p)
		{
			var s, e, m, i, d, nBits, mLen, eLen, eBias, eMax;
			mLen = el.mLen, eLen = el.len*8-el.mLen-1, eMax = (1<<eLen)-1, eBias = eMax>>1;
	
			i = bBE?0:(el.len-1); d = bBE?1:-1; s = a[p+i]; i+=d; nBits = -7;
			for (e = s&((1<<(-nBits))-1), s>>=(-nBits), nBits += eLen; nBits > 0; e=e*256+a[p+i], i+=d, nBits-=8);
			for (m = e&((1<<(-nBits))-1), e>>=(-nBits), nBits += mLen; nBits > 0; m=m*256+a[p+i], i+=d, nBits-=8);
	
			switch (e)
			{
				case 0:
					// Zero, or denormalized number
					e = 1-eBias;
					break;
				case eMax:
					// NaN, or +/-Infinity
					return m?NaN:((s?-1:1)*Infinity);
				default:
					// Normalized number
					m = m + Math.pow(2, mLen);
					e = e - eBias;
					break;
			}
			return (s?-1:1) * m * Math.pow(2, e-mLen);
		};
		var _En754 = function (a, p, v)
		{
			var s, e, m, i, d, c, mLen, eLen, eBias, eMax;
			mLen = el.mLen, eLen = el.len*8-el.mLen-1, eMax = (1<<eLen)-1, eBias = eMax>>1;
	
			s = v<0?1:0;
			v = Math.abs(v);
			if (isNaN(v) || (v == Infinity))
			{
				m = isNaN(v)?1:0;
				e = eMax;
			}
			else
			{
				e = Math.floor(Math.log(v)/Math.LN2);			// Calculate log2 of the value
				if (v*(c = Math.pow(2, -e)) < 1) { e--; c*=2; }		// Math.log() isn't 100% reliable
	
				// Round by adding 1/2 the significand's LSD
				if (e+eBias >= 1) { v += el.rt/c; }			// Normalized:  mLen significand digits
				else { v += el.rt*Math.pow(2, 1-eBias); } 		// Denormalized:  <= mLen significand digits
				if (v*c >= 2) { e++; c/=2; }				// Rounding can increment the exponent
	
				if (e+eBias >= eMax)
				{
					// Overflow
					m = 0;
					e = eMax;
				}
				else if (e+eBias >= 1)
				{
					// Normalized - term order matters, as Math.pow(2, 52-e) and v*Math.pow(2, 52) can overflow
					m = (v*c-1)*Math.pow(2, mLen);
					e = e + eBias;
				}
				else
				{
					// Denormalized - also catches the '0' case, somewhat by chance
					m = v*Math.pow(2, eBias-1)*Math.pow(2, mLen);
					e = 0;
				}
			}
	
			for (i = bBE?(el.len-1):0, d=bBE?-1:1; mLen >= 8; a[p+i]=m&0xff, i+=d, m/=256, mLen-=8);
			for (e=(e<<mLen)|m, eLen+=mLen; eLen > 0; a[p+i]=e&0xff, i+=d, e/=256, eLen-=8);
			a[p+i-d] |= s*128;
		};
	
		// Convert int64 to array with 3 elements: [lowBits, highBits, unsignedFlag]
		// '>>>' trick to convert signed 32bit int to unsigned int (because << always results in a signed 32bit int)
		var _DeInt64 = function (a, p) {
			var start = bBE ? 0 : 7, nsb = bBE ? 1 : -1, stop = start + nsb * 8, rv = [0,0, !el.bSigned], i, f, rvi;
			for (i = start, rvi = 1, f = 0;
				i != stop;
				rv[rvi] = (((rv[rvi]<<8)>>>0) + a[p + i]), i += nsb, f++, rvi = (f < 4 ? 1 : 0));
			return rv;
		};
		var _EnInt64 = function (a, p, v) {
			var start = bBE ? 0 : 7, nsb = bBE ? 1 : -1, stop = start + nsb * 8, i, f, rvi, s;
			for (i = start, rvi = 1, f = 0, s = 24;
				i != stop;
				a[p + i] = v[rvi]>>s & 0xff, i += nsb, f++, rvi = (f < 4 ? 1 : 0), s = 24 - (8 * (f % 4)));
		};
		
	
	
	
	
	
	
	
	
			// Class data
			var _sPattern	= '(\\d+)?([AxcbBhHsfdiIlLqQ])';
			var _lenLut	= {'A':1, 'x':1, 'c':1, 'b':1, 'B':1, 'h':2, 'H':2, 's':1, 'f':4, 'd':8, 'i':4, 'I':4, 'l':4, 'L':4, 'q':8, 'Q':8};
			var _elLut	= {	'A': {en:_EnArray, de:_DeArray},
						's': {en:_EnString, de:_DeString},
						'c': {en:_EnChar, de:_DeChar},
						'b': {en:_EnInt, de:_DeInt, len:1, bSigned:true, min:-Math.pow(2, 7), max:Math.pow(2, 7)-1},
						'B': {en:_EnInt, de:_DeInt, len:1, bSigned:false, min:0, max:Math.pow(2, 8)-1},
						'h': {en:_EnInt, de:_DeInt, len:2, bSigned:true, min:-Math.pow(2, 15), max:Math.pow(2, 15)-1},
						'H': {en:_EnInt, de:_DeInt, len:2, bSigned:false, min:0, max:Math.pow(2, 16)-1},
						'i': {en:_EnInt, de:_DeInt, len:4, bSigned:true, min:-Math.pow(2, 31), max:Math.pow(2, 31)-1},
						'I': {en:_EnInt, de:_DeInt, len:4, bSigned:false, min:0, max:Math.pow(2, 32)-1},
						'l': {en:_EnInt, de:_DeInt, len:4, bSigned:true, min:-Math.pow(2, 31), max:Math.pow(2, 31)-1},
						'L': {en:_EnInt, de:_DeInt, len:4, bSigned:false, min:0, max:Math.pow(2, 32)-1},
						'f': {en:_En754, de:_De754, len:4, mLen:23, rt:Math.pow(2, -24)-Math.pow(2, -77)},
						'd': {en:_En754, de:_De754, len:8, mLen:52, rt:0},
						'q': {en:_EnInt64, de:_DeInt64, bSigned:true},
						'Q': {en:_EnInt64, de:_DeInt64, bSigned:false}};
	
		// Unpack a series of n elements of size s from array a at offset p with fxn
		var _UnpackSeries = function (n, s, a, p)
		{
			for (var fxn = el.de, rv = [], i = 0; i < n; rv.push(fxn(a, p+i*s)), i++);
			return rv;
		};
	
			// Set the private bBE flag based on the format string - assume big-endianness
			bBE = (fmt.charAt(0) != '<');
	
			p = p?p:0;
			var re = new RegExp(_sPattern, 'g'), m, n, s, rv = [];
			while (m = re.exec(fmt))
			{
				n = ((m[1]==undefined)||(m[1]==''))?1:parseInt(m[1]);
				s = _lenLut[m[2]];
				if ((p + n*s) > a.length)
				{
					return undefined;
				}
				switch (m[2])
				{
					case 'A': case 's':
						rv.push(_elLut[m[2]].de(a, p, n));
						break;
					case 'c': case 'b': case 'B': case 'h': case 'H':
					case 'i': case 'I': case 'l': case 'L': case 'f': case 'd': case 'q': case 'Q':
						el = _elLut[m[2]];
						rv.push(_UnpackSeries(n, s, a, p));
						break;
				}
				p += n*s;
			}
			return Array.prototype.concat.apply([], rv);
		};
	
	
	
		function Utf8ArrayToStr(array) {
			var out, i, len, c;
			var char2, char3;
		
			out = "";
			len = array.length;
			i = 0;
			while(i < len) {
				c = array[i++];
				switch(c >> 4)
				{ 
				case 0: case 1: case 2: case 3: case 4: case 5: case 6: case 7:
					// 0xxxxxxx
					out += String.fromCharCode(c);
					break;
				case 12: case 13:
					// 110x xxxx   10xx xxxx
					char2 = array[i++];
					out += String.fromCharCode(((c & 0x1F) << 6) | (char2 & 0x3F));
					break;
				case 14:
					// 1110 xxxx  10xx xxxx  10xx xxxx
					char2 = array[i++];
					char3 = array[i++];
					out += String.fromCharCode(((c & 0x0F) << 12) |
								((char2 & 0x3F) << 6) |
								((char3 & 0x3F) << 0));
					break;
				}
			}
		
			return out;
		}
	


	
	function __read_bits_and_move_forward(n) {
		
		var local_read_bits = bit_array
		local_read_bits = local_read_bits.slice(0, n)

		bit_array = bit_array.slice(n, bit_array.length)

		return local_read_bits
	}
		
	
	
	var total_values = []

	// var nb_values = 17478
	for (var nb_value_index = 0; nb_value_index < nb_values; nb_value_index++) {

			if (!is_first_value_read) {

				previous_value_bits = __read_bits_and_move_forward(n_bits_value)
		
				is_first_value_read = true
				var new_bits_array = []
				var bit_count = 0
				var current_bit_build = ''


				for (var w = 0; w < previous_value_bits.length; w++) {
					if (bit_count < 8 && ((previous_value_bits.length - 1) > w)) {
						current_bit_build = current_bit_build + previous_value_bits[w]
					} else {
						new_bits_array.push(current_bit_build)
						bit_count = 0
						current_bit_build = ''
						current_bit_build = current_bit_build + previous_value_bits[w]
					}
					bit_count += 1
				}


				var bytes = []
				for (var z = 0; z < new_bits_array.length; z++) {
					var b = parseInt( new_bits_array[z].split('').join(''), 2 );
					bytes.push(b)
				}


				var unpacked = Unpack('>d', bytes, 0)[0]
				total_values.push(unpacked)
				
			}
		
		
			if (__read_bits_and_move_forward(1) == ['0']) {
				total_values.push(previous_value)
			}
			else {

				if (__read_bits_and_move_forward(1)[0] == '1') {


					n_leading_zeros = parseInt( __read_bits_and_move_forward(n_bits_number_of_leading_zeros), 2 )
		
					length_of_the_meaningful_xored_value = parseInt( __read_bits_and_move_forward(n_bits_length_of_the_meaningful_xored_value), 2 ) + BLOCK_SIZE_ADJUSTMENT
					n_trailing_zeros = n_bits_value - n_leading_zeros - length_of_the_meaningful_xored_value
				}
				var meaningful_bits_of_the_xored_value = __read_bits_and_move_forward(length_of_the_meaningful_xored_value)


				var zeroed_out = '' //Array(n_leading_zeros).fill('0')
				for (var g = 0; g < n_leading_zeros; g++) {
					zeroed_out += '0'
				}

				var trailing_zeros = ''
				for (var g = 0; g < n_trailing_zeros; g++) {
					trailing_zeros += '0'
				}

				
				var xored_value = zeroed_out + meaningful_bits_of_the_xored_value + trailing_zeros
		
				var xor=[[0,1],[1,0]];
				var value_bits = ''
				for (let i=0;i<xored_value.length;i++) {
					var r=xor[xored_value[i]][previous_value_bits[i]];
					value_bits += r
				} 

				previous_value_bits = value_bits
		
				var new_bits_array = []
				var bit_count = 0
				var current_bit_build = ''

				for (var w = 0; w < previous_value_bits.length + 1; w++) {
					if (bit_count < 8 && ((previous_value_bits.length) > w)) {
						current_bit_build = current_bit_build + previous_value_bits[w]
					} else {
						new_bits_array.push(current_bit_build)
						bit_count = 0
						current_bit_build = ''
						current_bit_build = current_bit_build + previous_value_bits[w]
					}
					bit_count += 1
				}
		
				var bytes = []
				for (var z = 0; z < new_bits_array.length; z++) {
					var b = parseInt(new_bits_array[z].split('').join(''), 2 );
					bytes.push(b)
				}
				
				var unpacked;
				try {
					unpacked = Unpack('>d', bytes, 0)[0]
				} catch (error) {
					unpacked = null
				}
				
				total_values.push(unpacked)
				previous_value = unpacked
			}

	}
	

	return total_values

}

export {
	decodeGorillaCompression
  };
