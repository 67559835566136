import store from '@/store';
import {
  VuexModule,
  Module,
  getModule,
  Action,
  Mutation,
} from 'vuex-module-decorators';
import { DateTime } from 'luxon';
import { getConfigEnv, jsonParse } from '@/utils/helpers';
import { decodeGorillaCompression } from '@/utils/testGorillaDecode.js';
import tasqsListModule from '@/store/modules/tasqsListModule';
import assetsModule from '@/store/modules/assetsModule';
import getNodeDescriptionCombinations from '@/graphql/cleanData/queries/getNodeDescriptionCombinations.graphql';
import getCleanTSDataTimeRangeV2 from '@/graphql/cleanData/queries/getCleanTSDataTimeRangeV2.graphql';
import getCleanTSDataTimeRangeV2ChartIQ from '@/graphql/cleanData/queries/getCleanTSDataTimeRangeV2ChartIQ.graphql';
import cleanDataApolloClient from '@/lib/appsync/cleanData';
import localForage from '@/lib/localForage';

@Module({
  dynamic: true,
  namespaced: true,

  name: 'tasqSignalsModule',
  store,
})
class TasqSignalsModule extends VuexModule {
  availableSignals = getConfigEnv('SIGNALS_ARRAY');

  currentSignals: any = [];

  selectedSignals: any = [];

  editableCurrentSignals: any = [];

  chartIQSignals: any = [];

  signalDescriptions: any = [];

  didLoadAllSignals: boolean = false;

  signalChartTime = 60;

  @Mutation
  resetSignals(): void {
    this.currentSignals = [];
  }

  @Mutation
  resetChartIQSignals(): void {
    this.chartIQSignals = [];
  }


  @Mutation
  resetSelectedSignals(): void {
    this.selectedSignals = [];
  }

  @Mutation
  setSelectedSignals(data): void {
    this.selectedSignals = data;
  }
  
  @Mutation
  setSignalChartTime(data): void {
    this.signalChartTime = data;
  }

  
  @Mutation
  selectSignal(signal_name): void {
	if (this.selectedSignals.indexOf(signal_name) > -1) {
		this.selectedSignals.splice(this.selectedSignals.indexOf(signal_name), 1)
	} else {
		this.selectedSignals.push(signal_name)
	}
    
  }




  @Mutation
  setDidLoadAllSignals(val): void {
    this.didLoadAllSignals = val;
  }


  

  @Mutation
  setSignalDescriptions(descriptions): void {
    this.signalDescriptions = descriptions;
  }



  


  @Mutation
  addSignal(signal): void {
    const currentSignalsNames = this.currentSignals.map((s) => s.name);
    if (currentSignalsNames.includes(signal.name)) return;
    this.currentSignals.push(signal);
  }


  @Mutation
  addEditableCurrentSignals(signal): void {
    const currentSignalsNames = this.editableCurrentSignals.map((s) => s.name);
    if (currentSignalsNames.includes(signal.name)) return;
    this.editableCurrentSignals.push(signal);
  }


  @Mutation
  addChartIQSignal(signal): void {

    // const signalDataExist = this.chartIQSignals.find((s) => s.name);

      this.chartIQSignals.push(signal);

  }

  @Mutation
  setCurrentSignal(data): void {
    this.currentSignals = data;
  }


  

  @Action
  async getSignalsForTasq(data: any = 60): Promise<void> {
	// data = 60
	this.setDidLoadAllSignals(false)
    const isOnlineApp = navigator.onLine;
    let isOfflinePreloading = false;
    const dataCount = 2000;
    let wellName = tasqsListModule.activeTasq?.wellName != null ? tasqsListModule.activeTasq?.wellName : assetsModule.activeTasq?.wellName;
    let daysLength = 60;
    const rawData = false;
    if (typeof data === 'object') {
      wellName = data.wellName;
      isOfflinePreloading = true;
      daysLength = data.daysLength;
    } else {
      daysLength = data;
      this.resetSignals();
    }
	this.resetSelectedSignals()
    const fetchSignalQuery = getCleanTSDataTimeRangeV2;

    if (!wellName) {
      return;
    }
    if (isOnlineApp) {
      const {
        data: {
          get_clean_ts_data_node_description_combinations_v2: nodeData,
        },
      } = await cleanDataApolloClient.query({
        query: getNodeDescriptionCombinations,
        variables: {
          operator: getConfigEnv('OPERATOR_PASCALED'),
          nodeid: wellName,
          filter: 'tasq_page',
        },
        fetchPolicy: 'network-only',
      });
      const backendSignals = jsonParse(nodeData)?.Description || [];
      const signalsToFetch = backendSignals;
	//   signalsToFetch.splice(signalsToFetch.indexOf('Inj. Flowrate'), 1);

	var copiedSignalsToFetch = JSON.parse(JSON.stringify(signalsToFetch))
	  for (var g = 0; g < copiedSignalsToFetch.length; g++) {
		if (copiedSignalsToFetch[g] != "Flowrate" && copiedSignalsToFetch[g] != "Static Pressure" && copiedSignalsToFetch[g] != "Tubing Pressure" && copiedSignalsToFetch[g] != "Casing Pressure") {
			signalsToFetch.splice(signalsToFetch.indexOf(copiedSignalsToFetch[g]), 1);
		}
	  }

	  signalsToFetch.push("TP-LP")
	  signalsToFetch.push("CP-LP")
	  signalsToFetch.push("CP-TP")


	  // Just need floats for density, other than that you can use ints


      if (tasqsListModule.enablePreloading) {
        await localForage.setItem(`getSignalsForTasq:getNodeDescriptionCombinations-${wellName}`, (signalsToFetch));
      }

	  if (!isOfflinePreloading) {
        this.setSignalDescriptions(signalsToFetch);
      }

      let fromTime = DateTime.utc().minus({ days: daysLength + tasqsListModule.fromNowBack }).toISO().slice(0, -5);
      const toTime = DateTime.utc().minus({ days: tasqsListModule.fromNowBack === 1 ? 0 : tasqsListModule.fromNowBack }).toISO().slice(0, -5);

      const offlineSignals: any = [];

      fromTime = (fromTime.split('T'))[0];

      tasqsListModule.setChartFromTime(fromTime);
      tasqsListModule.setChartToTime(toTime);




	  var signals_to_fetch_count = 0
	  var already_added_index = 0
	  var colors:any = [
		"#00FFEA", // teal
		"#F21688", // pink
		// "#FFFF5D", // yellow
		"#FFCD5C", // light orange yellow
		"#FFC8A5", // light orange
		"#FF94B8", // light pink
		// "#DB94C6", // light pink purple
		"#A994C6", // light purple
		"#94B0C6", // light blue
		"#94E2C6", // light green blue
		// "#94FFB0", // light green
		"#94ffdf", // light blue green
		"#94eaff", // light blue
		"#94c1ff", // another light blue
		"#a894ff", // light purple
		"#ffb194", // light orange
		"#90e64e", // lime green
		"#e64e4e", // light red
		"#b14ee6", // purple
		"#41c459", // green
		"#41c4b0" // blue
	]

      signalsToFetch.map(async (signal, idx) => {
		var raw = false
		if (signal.toLowerCase() == "tp-lp" || signal.toLowerCase() == "cp-lp" || signal.toLowerCase() == "cp-tp") {
			raw = false
		}

        const {
          data:
          {
            get_clean_ts_data_time_range_v2: {
              dataset,
              description,
              units,
            },
          },
        } = await cleanDataApolloClient.query({
          query: fetchSignalQuery,
          variables: {
            nodeid: wellName,
            description: [signal],
            // downsample_method: `{"method": "drop_nth_element", "n": 1}`,
            from_time: fromTime,
            to_time: toTime,
			raw: raw,
            operator: getConfigEnv('OPERATOR_PASCALED'),
			gorilla_compress: true,
			reg_freq: "5m",
			fillna_method: "locf"

          },
          fetchPolicy: 'network-only',
        });


        const parsedDataset = JSON.parse(dataset);
		if (description.length > 0 && parsedDataset.Value[0].start != undefined) {

			var date = new Date();
			date.toISOString()
			
			if (parsedDataset.Value[0] == undefined) {
				parsedDataset.Value[0] = {
					series: [],
					start: DateTime.now().toISO()
				}
			} else {
				parsedDataset.Value[0].series = decodeGorillaCompression(parsedDataset.Value[0].series, parsedDataset.Value[0].nb_values)
			}

			
			var color = "pink"
			if (description[0].toLowerCase() == "casing pressure") {
				color = "#DB94C6"
			} else if (description[0].toLowerCase() == "tubing pressure") {
				color = "#94FFB0"
			} else if (description[0].toLowerCase() == "static pressure") {
				color = "#FFFF5D"
			} else if (description[0].toLowerCase() == "tp-lp") {
				color = "#696969"
			} else if (description[0].toLowerCase() == "cp-tp") {
				color = "#baa79b"
			} else if (description[0].toLowerCase() == "cp-lp") {
				color = "#c2c2c2"
			} else {
				already_added_index += 1
				color = colors[already_added_index]
			}
	
	
	
			// 	if (entry.getText().toLowerCase() == "casing pressure" 
			// 		|| entry.getText().toLowerCase() == "tubing pressure" 
			// 		|| entry.getText().toLowerCase() == "static pressure"
			// 		|| entry.getText().toLowerCase() == "gas rate" 
			// 		|| entry.getText().toLowerCase() == "oil rate"
			// 		|| entry.getText().toLowerCase() == "target"
			// 		|| entry.getText().toLowerCase() == "target band"
			// 		|| entry.getText().toLowerCase() == "water rate"
			// 		|| entry.getText().toLowerCase() == "deferment range") {
			// 		entry.setOn(true)
			// 	} else {
			// 		entry.setOn(false)
	
			var selected = true
			if (description[0].toLowerCase() == "casing pressure"
				|| description[0].toLowerCase() == "tubing pressure"
				|| description[0].toLowerCase() == "static pressure"
				|| description[0].toLowerCase() == "gas rate"
				|| description[0].toLowerCase() == "oil rate"
				|| description[0].toLowerCase() == "target"
				|| description[0].toLowerCase() == "target band"
				|| description[0].toLowerCase() == "water rate"
				|| description[0].toLowerCase() == "deferment range") {
					this.selectSignal(description[0])
					selected = true
			} else {
				selected = false
			}
	
			const signalLocal = {
			  id: idx,
			  name: description[0],
			  dataset: parsedDataset.Value[0].series,
			  time: parsedDataset.Value[0].start,
			  unit: units[0],
			  color: color,
			  selected: selected
			};
	
	
	
			if (!isOfflinePreloading) {
			  this.addSignal(signalLocal);
			  this.addEditableCurrentSignals(signalLocal)
			}
			offlineSignals.push(signalLocal);
	
			
			signals_to_fetch_count += 1
			if (signals_to_fetch_count == signalsToFetch.length) {
				this.setDidLoadAllSignals(true)
			}
	
			// if (tasqsListModule.enablePreloading) {
			//   await localForage.setItem(`getSignalsForTasq:getCleanTSDataTimeRangeV2-${wellName}`, offlineSignals);
			// }

		}

      });
    }
  }

  @Action
  async getSignalsForTasqForChartIQ(daysBack = 0): Promise<void> {
    const wellName = tasqsListModule.activeTasq?.wellName != null ? tasqsListModule.activeTasq?.wellName : assetsModule.activeTasq?.wellName;
    let rawData = false;
    this.resetChartIQSignals()
    const fetchSignalQuery = getCleanTSDataTimeRangeV2ChartIQ;
    rawData = true;

    // if (!tasqsListModule.activeTasq?.wellName && !assetsModule.activeTasq?.wellName) return;

    if (!wellName) {
      return;
    }
    // const daysBack = [0, 30, 60, 90, 120, 150, 180];

    const signalNames = ['Tubing Pressure', 'Flowrate', 'Casing Pressure', 'Static Pressure'];

    const fromTime = DateTime.utc().minus({ days: daysBack === 0 ? 31 : 30 + daysBack }).toISO().slice(0, -5);
    const toTime = DateTime.utc().minus({ days: daysBack <= 0 ? 0 : daysBack - 1 }).toISO().slice(0, -5);

    signalNames.map(async (signal, idx) => {
		var raw = false
		if (signal.toLowerCase() == "tp-lp" || signal.toLowerCase() == "cp-lp" || signal.toLowerCase() == "cp-tp") {
			raw = false
		}
      const {
        data:
          {
            get_clean_ts_data_time_range_v2: {
              dataset,
              description,
              units,
            },
          },
      } = await cleanDataApolloClient.query({
        query: fetchSignalQuery,
        variables: {
          nodeid: wellName,
          description: [signal],
          from_time: fromTime,
          raw: raw,
          to_time: toTime,
          operator: getConfigEnv('OPERATOR_PASCALED'),
		  gorilla_compress: true,
		  fillna_method: "locf"
        },
        fetchPolicy: 'network-only'
      });
      const parsedDataset = JSON.parse(dataset);

      const signalLocal = {
        id: idx,
        name: description[0],
        dataset: parsedDataset.Value[0],
        time: parsedDataset.time[0],
        unit: units[0],
      };

      this.addChartIQSignal(signalLocal);
    });
  }
}

export default getModule(TasqSignalsModule);